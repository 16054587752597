import React from 'react';
import {
  ThemeProvider,
  Button,
  TaskBar,
  GlobalStyle,
  ProgressBar,
  List,
  Modal,
  Tree,
} from '@react95/core';
import { useHarmonicIntervalFn } from 'react-use';

import {
  ReaderClosed,
  CdMusic,
  WindowsExplorer,
  Sol1,
  Wininet32546,
} from '@react95/icons';

// @ts-ignore
import { useClippy, ClippyProvider, AGENTS } from '@react95/clippy';
import { HUNTER_QUOTES } from './quotes';
import '@react95/icons/icons.css';
import './App.css';

function getRandomInt(max: number): number {
  let n = Math.floor(Math.random() * max);
  while (n > max) {
    n = Math.floor(Math.random() * max);
  }
  return n;
}

const ClippyComponent = () => {
  const { clippy } = useClippy();
  return (
    <Button
      style={{
        width: 200,
        marginTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      onClick={() => {
        setTimeout(() => {
          clippy.stop();
          clippy.speak(HUNTER_QUOTES[getRandomInt(HUNTER_QUOTES.length - 1)]);
          clippy.animate();
        }, 500);
      }}
    >
      Click for Clippy Advice!
    </Button>
  );
};

const frameRef = React.createRef<HTMLDivElement>();
function App() {
  const [progress, setProgress] = React.useState(1);
  useHarmonicIntervalFn(() => {
    if (progress > 100) {
      setProgress(100);
    } else if (progress < 100) {
      const i = (Math.random() - Math.sin(Math.random())) * 20;
      setProgress(Math.round(progress + i + 10));
    }
  }, 2500);

  const [first, toggleFirst] = React.useState(false);
  const [second, toggleSecond] = React.useState(false);
  const [modalImg, setModalImg] = React.useState<string | null>(null);

  const closeFirst = () => toggleFirst(false);
  const closeSecond = () => toggleSecond(false);

  return (
    <ThemeProvider>
      <GlobalStyle />
      <div ref={frameRef} className="App-container">
        <ClippyProvider agentName={AGENTS.CLIPPY}>
          <h1>FriendsForSeth.com!!! (coming soon...)</h1>
          <h2>
            <b>ovbi...</b>
          </h2>
          <ProgressBar width={500} percent={progress} />
          <ClippyComponent />
          {first && (
            <Modal
              icon={<WindowsExplorer variant="16x16_4" />}
              title="Windows Explorer"
              closeModal={() => {
                closeFirst();
                setModalImg(null);
              }}
              width="600"
              height="400"
            >
              <Tree
                data={[
                  {
                    id: 0,
                    label: 'party',
                    icon: <CdMusic />,
                    children: [
                      {
                        id: 2,
                        label: 'clarbb',
                        icon: (
                          <Sol1
                            onClick={() => setModalImg('/assets/party.gif')}
                          />
                        ),
                      },
                      {
                        id: 3,
                        label: 'casual',
                        icon: (
                          <Wininet32546
                            onClick={() => setModalImg('/assets/nyan.gif')}
                          />
                        ),
                      },
                    ],
                  },
                ]}
              />
              {modalImg && (
                <img
                  src={modalImg}
                  alt=""
                  width={300}
                  style={{ float: 'right' }}
                />
              )}
            </Modal>
          )}
          {second && (
            <Modal
              defaultPosition={{ x: 50, y: 50 }}
              width="300"
              height="200"
              icon={<ReaderClosed variant="16x16_4" />}
              title="Local Disk (C:)"
              closeModal={closeSecond}
            />
          )}
        </ClippyProvider>
        <div style={{ marginTop: 46 }}>
          <img src="assets/construction.gif" alt="" />
        </div>
      </div>

      <TaskBar
        list={
          <List>
            <List.Item
              icon={<ReaderClosed variant="32x32_4" />}
              onClick={() => toggleSecond(true)}
            >
              Local Disk (C:)
            </List.Item>
            <List.Item
              icon={<WindowsExplorer variant="32x32_4" />}
              onClick={() => {
                toggleFirst(true);
              }}
            >
              Windows Explorer
            </List.Item>
          </List>
        }
      />
    </ThemeProvider>
  );
}

export default App;
